import React from "react"
import { useTheme } from "styled-components"

import { Container } from "components/atoms/Grid"
import Errored from "components/molecules/Errored"
import PageWrapper from "components/molecules/PageWrapper"

const Page404 = () => {
  const theme = useTheme()
  return (
    <PageWrapper title="Page not found">
      <Container
        marginBottom={theme.spacing.xLarge}
        marginTop={theme.spacing.xLarge}
      >
        <Errored is404page />
      </Container>
    </PageWrapper>
  )
}
export default Page404
