import React from "react"
import styled from "styled-components"

import { h3 } from "base_css/mixins"
import { ButtonStyled } from "components/atoms/Button"
import { CustomLink } from "components/atoms/CustomLink"
import { Cell, Container, Grid } from "components/atoms/Grid"

const SContainer = styled(Container)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const SHeader = styled.h1`
  ${h3} /* stylelint-disable-next-line */
  margin: ${({ theme }) => theme.spacing.medium} 0;
`

const SText = styled.p`
  color: ${({ theme }) => theme.color.grey.darkest};
`

interface IErrored {
  className?: string
  is404page?: boolean
  clearError?: () => void
}

const Errored = ({ className, is404page, clearError }: IErrored) => (
  <SContainer className={className}>
    <Grid>
      <Cell width={12} mdWidth={8} mdLeft={3}>
        <SHeader>
          {is404page
            ? "Hmm, we can't find that page..."
            : "Oops, something went wrong"}
        </SHeader>
        <SText>
          {is404page
            ? "Sorry about that! But we can help you find your way home."
            : "Please refresh the page"}
        </SText>

        <CustomLink
          href="/"
          onClick={clearError}
          analyticsID="Errored - take me home button"
        >
          <ButtonStyled arrow={true} variant={is404page ? "pink" : undefined}>
            Take me home
          </ButtonStyled>
        </CustomLink>
      </Cell>
    </Grid>
  </SContainer>
)

export default Errored
